export const questionnaire = [
  {
    id: 1,
    question: "What best describes you? ",
    type: "singleselect",
    layout: "grid",
    options: [
      {
        id: 1,
        value: "a",
        label: "I am interested in CycleSaver’s products for myself.",
        selected: false,
        img: "user.png",
      },
      {
        id: 2,
        value: "b",
        label: "I am interested in offering CycleSaver’s product to my company’s employees.",
        selected: false,
        img: "group.png",
      },
      { id: 3, value: "c", label: "Other (specify)", type: "manual", selected: false },
    ],
  },
  {
    id: 2,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    question: "Which of the following bikeshare schemes have you used in the past 12 months?",
    type: "multiselect",
    layout: "grid",

    options: [
      { id: 1, value: "a", label: "Santander Cycles", selected: false, img: "santander.png" },
      { id: 2, value: "b", label: "TIER", selected: false, img: "tier.jpeg" },
      { id: 3, value: "c", label: "Forest", selected: false, img: "forest.jpeg" },
      { id: 4, value: "d", label: "Lime", selected: false, img: "lime.png" },
      { id: 5, value: "e", label: "Beryl", selected: false, img: "beryllogo.png" },
      { id: 6, value: "f", label: "Nextbike", selected: false, img: "nextbike.png" },
      { id: 7, value: "g", label: "None - I haven’t used any.", selected: false, effect: "clear_others" },
    ],
  },
  {
    id: 3,
    conditionQID: 2,
    conditionAnswerIDs: [1, 2, 3, 4, 5, 6],
    question: "Which is your preferred provider?",
    type: "singleselect",
    layout: "grid",

    options: [
      { id: 1, value: "a", label: "Santander Cycles", selected: false, img: "santander.png" },
      { id: 2, value: "b", label: "TIER", selected: false, img: "tier.jpeg" },
      { id: 3, value: "c", label: "Forest", selected: false, img: "forest.jpeg" },
      { id: 4, value: "d", label: "Lime", selected: false, img: "lime.png" },
      { id: 5, value: "e", label: "Beryl", selected: false, img: "beryllogo.png" },
      { id: 6, value: "f", label: "Nextbike", selected: false, img: "nextbike.png" },
      { id: 7, value: "g", label: "None - I haven’t used any.", selected: false, effect: "clear_others" },
    ],
  },
  {
    id: 4,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    layout: "list",

    question: "How often do you cycle? (for travel commuting, not fitness)",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Frequently (4 or more days per week)", selected: false },
      { id: 2, value: "b", label: "Sometimes (1-3 days per week)", selected: false },
      { id: 3, value: "c", label: "Rarely (<1 day per week)", selected: false },
      { id: 4, value: "d", label: "Never", selected: false },
    ],
  },
  {
    id: 5,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    layout: "list",
    question:
      "What are the primary reason(s) you do not use bike sharing schemes as a way of getting to and from work?",
    type: "multiselect",
    options: [
      { id: 1, value: "a", label: "I already use bike sharing as a means of getting to work", selected: false },
      { id: 2, value: "b", label: "I prefer to use my own bicycle", selected: false },
      { id: 3, value: "c", label: "Cost", selected: false },
      { id: 4, value: "d", label: "Safety concerns", selected: false },
      { id: 5, value: "e", label: "Accessibility (none around my home or work)", selected: false },
      { id: 6, value: "f", label: "Distance - it’s too far", selected: false },
      { id: 7, value: "g", label: "Habit - I’m used to my current commute", selected: false },
      { id: 8, value: "h", label: "Lack of facilities (e.g. no showers at work)", selected: false },
      { id: 9, value: "i", label: "Other (specify)", type: "manual", selected: false },
    ],
  },
  {
    id: 6,
    conditionQID: 1,
    conditionAnswerIDs: [1, 3],
    layout: "list",

    question:
      "What are the primary reasons that you would consider using a bike sharing scheme for your regular commute?",
    type: "multiselect",
    options: [
      { id: 7, value: "g", label: "Save on commuting costs", selected: false },
      { id: 2, value: "b", label: "Flexibility (one-way trips)", selected: false },
      { id: 3, value: "c", label: "No risk of theft", selected: false },
      { id: 4, value: "d", label: "Better physical and mental health", selected: false },
      { id: 5, value: "e", label: "No storage space requirements", selected: false },
      { id: 1, value: "a", label: "Access to e-bikes", selected: false },
      { id: 6, value: "f", label: "No large upfront costs and commitment", selected: false },
    ],
  },
  {
    id: 7,
    layout: "list",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    type: "multiselect",

    question: "What challenges do you face when implementing new employee benefits? Select all that apply.",
    options: [
      { id: 1, value: "a", label: "Budget constraints", selected: false },
      { id: 2, value: "b", label: "Employee engagement", selected: false },
      { id: 3, value: "c", label: "Administrative complexity", selected: false },
      { id: 4, value: "d", label: "Communicating benefits to employees", selected: false },
      { id: 5, value: "e", label: "Measuring program effectiveness", selected: false },
    ],
  },
  {
    id: 8,
    layout: "list",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question: "How important is health and wellness in your company’s benefits offerings?",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Very important", selected: false },
      { id: 2, value: "b", label: "Somewhat important", selected: false },
      { id: 3, value: "c", label: "Neutral", selected: false },
      { id: 4, value: "d", label: "Not very important", selected: false },
      { id: 5, value: "e", label: "Not at all important", selected: false },
    ],
  },
  {
    id: 9,
    layout: "list",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question:
      "How urgently has your organization prioritised the implementation of sustainable transport options for employees?",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Urgent priority (1 - 6 months)", selected: false },
      { id: 2, value: "b", label: "High Priority (6 - 12 month)", selected: false },
      { id: 3, value: "c", label: "Moderate Priority (12 - 24 months)", selected: false },
      { id: 4, value: "d", label: "Low Priority (24+ months)", selected: false },
    ],
  },
  {
    id: 10,
    layout: "widegrid",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question: "Which Employee Benefit platform do you use? ",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Benefex", selected: false, img: "benefex.jpg" },
      { id: 2, value: "a", label: "Caboodle", selected: false, img: "caboodle.jpeg" },
      { id: 3, value: "a", label: "Darwin", selected: false, img: "darwin.jpeg" },
      { id: 4, value: "a", label: "RewardGateway", selected: false, img: "rewardgateway.jpeg" },
      { id: 5, value: "a", label: "Zest Benefits", selected: false, img: "zest.png" },
      { id: 6, value: "a", label: "Ben", selected: false, img: "ben.webp" },
      { id: 7, value: "a", label: "Zhoosh", selected: false, img: "zhoosh.png" },
      { id: 8, value: "a", label: "Vivup", selected: false, img: "vivup.png" },
      { id: 9, value: "a", label: "Barnett Waddingham", selected: false, img: "barnettwaddingham.jpg" },

      { id: 10, value: "b", label: "Other (specify)", type: "manual", selected: false },
      { id: 11, value: "c", label: "None", selected: false, effect: "clear_others" },
    ],
  },
  {
    id: 11,
    layout: "list",
    conditionQID: 1,
    conditionAnswerIDs: [2],
    question: "Would you consider using CycleSaver if it was not on your Employee Benefits platform of choice? ",
    type: "singleselect",
    options: [
      { id: 1, value: "a", label: "Yes, depending on the complexity", selected: false },
      { id: 2, value: "b", label: "No, all benefits need to be on our chosen platform", selected: false },
      { id: 3, value: "c", label: "Unsure", selected: false },
    ],
  },
  {
    id: 12,
    question: "Last step before you spin to win! 🤩",
    type: "form",
    layout: "list",
  },
];
