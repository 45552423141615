// import logo from './logo.svg';
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Login from "./screens/Login/Login"; // Ensure the path is correct based on your file structure

import Form from "./screens/Form/Form";
// import { UserProvider } from "./context/AppContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewApplication from "./screens/ViewApplication/ViewApplication";
import ResponsiveAppBar from "./components/Navbar";
function App() {
  return (
    <Router>
      {/* <UserProvider> */}
      <Routes>
        <Route path="/" element={<Form />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        limit={5}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* </UserProvider> */}
    </Router>
  );
}

export default App;
