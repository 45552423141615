import React, { useEffect, useRef } from "react";

const VideoPlayer = () => {
  const videoRef = useRef<any>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  }, []);

  return (
    <div className="w-full flex justify-center mt-5 relative" style={{ paddingBottom: "56.25%", position: "relative" }}>
      <video
        ref={videoRef}
        width="640"
        height="390"
        autoPlay
        loop
        muted // Mute the video for autoplay to work
        controls={false}
        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
      >
        <source src={require("../assets/Survey_Video_HQ.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
